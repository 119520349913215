<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Help Topic</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="500">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Note Help Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Title-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_title"
                    label="Title"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
                <!--Route-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_route"
                    label="Route Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
                <!--Text-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="d_text"
                    label="Text"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "helpTopicsStore/getEntryField",
  mutationType: "helpTopicsStore/updateEntryField"
});

export default {
  name: "HelpTopicEntry",
  computed: {
    ...mapState({
      helpTopicsStore: state => state.helpTopicsStore
    }),
    ...mapFields(["show", "valid", "mode", "id", "d_title", "d_route", "d_text"])
  },

  methods: {
    async newEntry() {
      this.valid = true;
      this.mode = 1;
      this.id = "";
      this.d_title = "";
      this.d_route = "";
      this.d_text = "";
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["helpTopicsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("helpTopicsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("helpTopicsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
